















































import { Vue, Component } from "vue-property-decorator";
import MainMap from "@/newViews/newPublic/newPublicMap.vue";
import { MetaModule as metaStore, JWTModule } from "@/store/modules";

@Component({
  components: {
    MainMap,
  },
})
export default class extends Vue {
  vehicleNumber: string = "";
  vehicleId: string | null = "";

  async created() {
    await metaStore.loadVehicleListDD();
  }

  get options1() {
    return metaStore.vehicleList;
  }

  get isLoggedIn() {
    return JWTModule.isLoggedIn;
  }

  search() {
    if (this.vehicleNumber === "") {
      alert("the field is empty");
      return;
    }

    const vehicleToTrack = this.options1.find(
      (x) => x.vehicleNumber === this.vehicleNumber
    );
    if (vehicleToTrack && vehicleToTrack.id) {
      this.vehicleId = vehicleToTrack.id.toString();

      this.$router.push({
        name: "TrackVehicle",
        query: { vn: this.vehicleId },
      });
      location.reload();
    }
  }
}
